<template>
  <div class="layout-participant">
    <Navbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/layouts/components/Footer.vue';
import Navbar from '@/layouts/components/Navbar.vue';

export default {
  components: {
    Footer,
    Navbar
  },
};
</script>
